// src/layouts/Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaHome,
  FaClock,
  FaFileAlt,
  FaBuilding,
  FaCogs,
  FaUserFriends,
  FaEnvelopeOpenText,
  FaBars,
  FaTimes,
} from 'react-icons/fa';

import dashboardLogo from '../assets/dashboard_logo.svg';

const Sidebar = ({ role }) => {
  const [collapsed, setCollapsed] = useState(false);

  // Toggle the sidebar collapse state
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Set sidebar width based on collapse state
  const sidebarWidth = collapsed ? '60px' : '220px';

  // Helper function to render a navigation item.
  // When collapsed, only the icon is shown and the label is available via the title attribute.
  const renderMenuItem = (to, icon, label) => (
    <li className="nav-item">
      <Link to={to} className="nav-link text-white" title={collapsed ? label : undefined}>
        {icon}
        {!collapsed && <span className="ms-2">{label}</span>}
      </Link>
    </li>
  );

  return (
    <div
      className="custom-bg text-white d-flex flex-column justify-content-between"
      style={{ width: sidebarWidth, transition: 'width 0.3s' }}
    >
      <div>
        {/* Header: Logo and toggle button */}
        <div className=" sidebar d-flex align-items-center justify-content-between border-bottom p-3">
          {!collapsed && (
            <img
              src={dashboardLogo}
              alt="Attendix Logo"
              className="img-fluid"
              style={{ maxHeight: '40px' }}
            />
          )}
          <button className="btn btn-sm btn-outline-light" onClick={toggleSidebar}>
            {collapsed ? <FaBars /> : <FaTimes />}
          </button>
        </div>
        {/* Navigation menu */}
        <ul className="nav flex-column mt-3">
          {renderMenuItem("/", <FaHome />, "Dashboard")}
          {(role === 'admin' || role === 'manager') &&
            renderMenuItem("/reports-dashboard", <FaUserFriends />, "Reports Dashboard")}
          {renderMenuItem("/attendance", <FaClock />, "Attendance")}
          {(role === 'admin' || role === 'manager') &&
            renderMenuItem("/attendance-history", <FaClock />, "Attendance History")}
          {role === 'employee' && renderMenuItem("/attendance-logs", <FaFileAlt />, "Attendance Logs")}
          {renderMenuItem("/timesheet", <FaClock />, "TimeSheet")}
          {(role === 'admin' || role === 'manager') && (
            <>
              {renderMenuItem("/departments", <FaBuilding />, "Departments")}
              {renderMenuItem("/leave-approval", <FaUserFriends />, "Approve Leave")}
            </>
          )}
          {(role === 'manager' || role === 'employee') && (
            <>
              {renderMenuItem("/leave-request", <FaEnvelopeOpenText />, "Request Leave")}
              {renderMenuItem("/leave-dashboard", <FaEnvelopeOpenText />, "My Leave")}
            </>
          )}
          {role === 'admin' && (
            <>
              {renderMenuItem("/employee-enrollment", <FaUserFriends />, "Employee Enrollment")}
              {renderMenuItem("/employee-list", <FaCogs />, "All Employee")}
              {renderMenuItem("/settings", <FaCogs />, "Settings")}
            </>
          )}
        </ul>
      </div>
      <div className="p-3 border-top text-center">
        <small>© 2025 Attendix</small>
      </div>
    </div>
  );
};

export default Sidebar;
