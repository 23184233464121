// src/pages/SettingsPage.js
import React, { useState, useEffect } from 'react';

// Helper to build a full URL if the path is relative
const buildLogoUrl = (path) => {
  if (!path) return '';
  // If it already starts with http(s) or data, return as-is
  if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('data:')) {
    return path;
  }
  // Otherwise, assume it's a relative path on your server

  return `${process.env.REACT_APP_API_URL}${path}`;
};

const SettingsPage = ({ token }) => {
  const [settings, setSettings] = useState([]);
  const [logoPreview, setLogoPreview] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchSettings();
  }, [token]);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}settings`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error || 'Failed to fetch settings');
      }
      const data = await response.json();
      setSettings(data);

      // If there's a SYSTEM_LOGO_PATH, convert it to a full URL for preview
      const logoSetting = data.find(item => item.setting_key === 'SYSTEM_LOGO_PATH');
      if (logoSetting && logoSetting.setting_value) {
        setLogoPreview(buildLogoUrl(logoSetting.setting_value));
      } else {
        setLogoPreview(null);
      }
    } catch (error) {
      console.error(error);
      setMessage(error.message);
    }
  };

  const handleInputChange = (index, newValue) => {
    const updatedSettings = [...settings];
    updatedSettings[index].setting_value = newValue;
    setSettings(updatedSettings);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(settings)
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to update settings');
      }
      setMessage('Settings updated successfully!');
      fetchSettings(); // Refresh after saving
    } catch (error) {
      console.error(error);
      setMessage(error.message);
    }
  };

  const handleLogoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Show a local preview right away
      setLogoPreview(URL.createObjectURL(file));

      const formData = new FormData();
      formData.append('logo', file);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}settings/logo`, {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${token}` },
          body: formData
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || 'Error uploading logo');
        }
        setMessage('Logo uploaded successfully!');
        fetchSettings();
      } catch (error) {
        console.error(error);
        setMessage(error.message);
      }
    }
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-4">System Settings</h3>
      {message && <div className="alert alert-info">{message}</div>}

      {/* Logo Upload Section */}
      <div className="mb-4">
        <h5>System Logo</h5>
        <div className="mb-2">
          {logoPreview ? (
            <img
              src={logoPreview}
              alt="Logo Preview"
              style={{ maxWidth: '300px', width: '100%' }}
              className="img-fluid"
            />
          ) : (
            <p>No logo uploaded.</p>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="logoUpload" className="form-label">Upload New Logo</label>
          <input
            type="file"
            id="logoUpload"
            className="form-control"
            accept="image/*"
            onChange={handleLogoUpload}
          />
        </div>
      </div>

      {/* Settings as a responsive grid */}
      {settings.length === 0 ? (
        <p>No settings found.</p>
      ) : (
        <div className="row">
          {settings.map((item, index) => (
            <div key={item.setting_key} className="col-12 col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h6 className="card-title">{item.setting_key}</h6>
                  <input
                    type="text"
                    className="form-control"
                    value={item.setting_value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <button className="btn btn-primary mt-3" onClick={handleSave}>
        Save Changes
      </button>
    </div>
  );
};

export default SettingsPage;
