// src/pages/EmployeeList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const EmployeeList = ({ token }) => {
  const [employees, setEmployees] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, [token]);

  useEffect(() => {
    if (filter.trim() === '') {
      setFilteredEmployees(employees);
    } else {
      const lowerFilter = filter.toLowerCase();
      const filtered = employees.filter(emp => {
        const fullName = `${emp.first_name} ${emp.last_name}`.toLowerCase();
        return (
          fullName.includes(lowerFilter) ||
          (emp.email && emp.email.toLowerCase().includes(lowerFilter)) ||
          (emp.role && emp.role.toLowerCase().includes(lowerFilter))
        );
      });
      setFilteredEmployees(filtered);
    }
  }, [filter, employees]);

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}employees_list`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setEmployees(data);
      } else {
        setMessage(data.error || 'Failed to fetch employees.');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while fetching employees.');
    }
  };

  const handleEdit = (employeeId) => {
    navigate(`/employees/edit/${employeeId}`);
  };

  const handleDelete = async (employeeId) => {
    if (!window.confirm("Are you sure you want to delete this employee?")) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}employees/${employeeId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message || 'Employee deleted successfully.');
        fetchEmployees(); // Refresh list
      } else {
        setMessage(data.error || 'Failed to delete employee.');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while deleting employee.');
    }
  };

  return (
    <div className="container mt-4">
      <h3>Employee List</h3>
      {message && <div className="alert alert-info">{message}</div>}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Filter by name, email, or role..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.map(emp => (
            <tr key={emp.employee_id}>
              <td>{emp.employee_id}</td>
              <td>{emp.first_name} {emp.last_name}</td>
              <td>{emp.email}</td>
              <td>{emp.role}</td>
              <td>
                <button className="btn custom-gradiant btn-sm me-2" onClick={() => handleEdit(emp.employee_id)}>
                  Edit
                </button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(emp.employee_id)}>
                  Terminate
                </button>
              </td>
            </tr>
          ))}
          {filteredEmployees.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center">No employees found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeList;