// /frontend/src/pages/ForgotPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrorMsg('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}forgot_password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (!response.ok) {
        setErrorMsg(data.error || 'Error occurred.');
      } else {
        setMessage(data.message || 'If an account exists, a reset link has been sent.');
      }
    } catch (error) {
      console.error(error);
      setErrorMsg('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="card p-4 shadow-lg" style={{ maxWidth: "400px", width: "100%" }}>
        <h2 className="text-center">Forgot Password</h2>
        {message && <div className="alert alert-success">{message}</div>}
        {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email Address:</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your registered email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn custom-gradiant mt-3 w-100">
            Send Reset Link
          </button>
        </form>
        <button className="btn btn-link mt-3 w-100" onClick={() => navigate('/login')}>
          Back to Login
        </button>
      </div>
    </div>

  );
};

export default ForgotPassword;
