// /frontend/src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import attendixLogo from '../assets/attendix_logo.svg';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    const payload = { email, password };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      const data = await response.json();

      if (!response.ok) {
        setErrorMsg(data.error || 'Login failed.');
        return;
      }

      if (data.access_token && data.refresh_token) {
        // Decode the access token to extract user details and must_change_password flag
        const decoded = jwtDecode(data.access_token);
        const fullName = decoded.full_name || 'Unknown User';
        const userEmail = decoded.email || email;
        const userRole = decoded.role || 'employee';
        const mustChange = decoded.must_change_password || false;

        // Store both access and refresh tokens in localStorage
        localStorage.setItem('user', JSON.stringify({
          token: data.access_token,
          refreshToken: data.refresh_token,
          fullName,
          email: userEmail,
          role: userRole
        }));

        // Pass user data to parent (App.js)
        onLogin({
          fullName,
          email: userEmail,
          role: userRole,
          token: data.access_token,
          refreshToken: data.refresh_token // Include refresh token for App.js
        });

        // Redirect based on the must_change_password flag
        if (mustChange) {
          navigate('/change_password');
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      console.error(err);
      setErrorMsg('An error occurred during login.');
    }
  };

  return (
    <section className="h-100 gradient-form">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                {/* Left Side */}
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">
                    <div className="text-center">
                      <img
                        src={attendixLogo}
                        alt="Attendix Logo"
                        style={{ width: "200px", marginBottom: "1rem" }}
                      />
                    </div>

                    {errorMsg && (
                      <div className="alert alert-danger">{errorMsg}</div>
                    )}

                    <form onSubmit={handleSubmit}>
                      <p>Please login to your account</p>
                      <div className="form-outline mb-4">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="text-center d-grid pt-1 mb-5 pb-1">
                        <button type="submit" className="btn btn-primary custom-gradiant mb-3">
                          LOG IN
                        </button>
                        <a className="text-muted mt-2" href="/forgot_password">
                          Forgot password?
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Right Side */}
                <div className="col-lg-6 d-lg-block d-sm-none d-flex align-items-center custom-gradiant">
                  <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                    <h4 className="mb-4">
                      Traditional attendance methods drain time and invite errors.
                    </h4>
                    <p className="small mb-0">
                      Smart Attendance System leverages cutting-edge facial recognition
                      <br />
                      ✅ Automate tracking in real time.
                      <br />
                      ✅ Boost accuracy with AI-powered identification.
                      <br />
                      ✅ Save hours for teachers, employers, and administrators.
                      <br />
                      ✅ Secure data with encrypted logs for reports or audits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;