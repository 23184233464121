// src/pages/EmployeeEnrollment.js
import React, { useState, useEffect } from 'react';

const EmployeeEnrollment = ({ token }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('employee');
  const [managerId, setManagerId] = useState('');
  const [imageFile, setImageFile] = useState(null);

  // New fields
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hireDate, setHireDate] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [allowedLocations, setAllowedLocations] = useState('');

  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [managers, setManagers] = useState([]);
  const [message, setMessage] = useState('');
  const [imagePreview, setImagePreview] = useState(null);

  // Fetch all departments
  useEffect(() => {
    const fetchDepts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}departments`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setDepartments(data);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (token) fetchDepts();
  }, [token]);

  // Fetch possible managers (filter for role 'manager' or 'admin')
  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}employees_list`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          const managerList = data.filter(
            (emp) => emp.role === 'manager' || emp.role === 'admin'
          );
          setManagers(managerList);
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (token) fetchManagers();
  }, [token]);

  // Handle file input change and update preview
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(null);
    }
  };

  // Handle multiple department selection
  const handleDepartmentChange = (e) => {
    const options = Array.from(e.target.selectedOptions);
    const values = options.map((opt) => opt.value);
    setSelectedDepartments(values);
  };

  // Submit enrollment form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!firstName || !lastName || !email || !imageFile) {
      setMessage('Please fill in all required fields and select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('role', role);
    if (managerId) {
      formData.append('manager_id', managerId);
    }
    // New optional fields
    if (dateOfBirth) formData.append('date_of_birth', dateOfBirth);
    if (phoneNumber) formData.append('phone_number', phoneNumber);
    if (hireDate) formData.append('hire_date', hireDate);
    if (jobTitle) formData.append('job_title', jobTitle);
    if (allowedLocations) formData.append('allowed_locations', allowedLocations);

    formData.append('image', imageFile);
    selectedDepartments.forEach((deptId) => {
      formData.append('department_ids[]', deptId);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}employees`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(`Error: ${data.error || 'Unknown error'}`);
      } else {
        setMessage('Employee enrolled successfully!');
        // Reset fields
        setFirstName('');
        setLastName('');
        setEmail('');
        setRole('employee');
        setManagerId('');
        setDateOfBirth('');
        setPhoneNumber('');
        setHireDate('');
        setJobTitle('');
        setAllowedLocations('');
        setSelectedDepartments([]);
        setImageFile(null);
        setImagePreview(null);
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while enrolling employee.');
    }
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-4">Create Employee Account</h3>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          {/* First and Last Name */}
          <div className="col-md-6 mb-3">
            <label className="form-label">First Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Last Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="row">
          {/* Email and Role */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Email <span className="text-danger">*</span></label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Role</label>
            <select
              className="form-control"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="employee">Employee</option>
              <option value="manager">Manager</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>

        <div className="row">
          {/* Manager Selection and Departments */}
          <div className="col-md-6 mb-3">
            <label className="form-label">Assign Manager</label>
            <select
              className="form-control"
              value={managerId}
              onChange={(e) => setManagerId(e.target.value)}
            >
              <option value="">No Manager</option>
              {managers.map((mgr) => (
                <option key={mgr.employee_id} value={mgr.employee_id}>
                  {mgr.first_name} {mgr.last_name} (ID: {mgr.employee_id})
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Departments</label>
            <select
              multiple
              className="form-control"
              value={selectedDepartments}
              onChange={handleDepartmentChange}
            >
              {departments.map((dept) => (
                <option key={dept.department_id} value={dept.department_id}>
                  {dept.department_name}
                </option>
              ))}
            </select>
            <small className="text-muted">
              Hold CTRL (or CMD on Mac) to select multiple.
            </small>
          </div>
        </div>

        {/* New Optional Fields */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Hire Date</label>
            <input
              type="date"
              className="form-control"
              value={hireDate}
              onChange={(e) => setHireDate(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Job Title</label>
            <input
              type="text"
              className="form-control"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Allowed Locations (JSON or CSV)</label>
          <textarea
            className="form-control"
            value={allowedLocations}
            onChange={(e) => setAllowedLocations(e.target.value)}
            placeholder='e.g. {"latitude": 40.7128, "longitude": -74.0060}'
          />
        </div>

        {/* File Upload and Image Preview */}
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Upload Face Image <span className="text-danger">*</span></label>
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
              accept="image/*"
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Image Preview</label>
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Face Preview"
                className="img-fluid"
                style={{ maxWidth: '300px' }}
              />
            ) : (
              <p>No image selected.</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-100">
              Enroll Employee
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmployeeEnrollment;
