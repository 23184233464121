// src/hooks/UseTokenRefresh.js
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

/**
 * Custom hook to manage token refreshing.
 * @param {string} refreshToken - The refresh token stored in localStorage.
 * @param {function} setToken - Callback to update the access token in state.
 * @param {function} logout - Callback to log the user out if refreshing fails.
 */
const useTokenRefresh = (refreshToken, setToken, logout) => {
  useEffect(() => {
    if (!refreshToken) return;

    // Get the current access token from localStorage
    const getAccessToken = () => {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      return storedUser?.token;
    };

    // Check if a token is expiring soon (within 2 minutes)
    const isTokenExpiringSoon = (token, threshold = 120) => {
      try {
        const decoded = jwtDecode(token);
        const now = Date.now() / 1000; // Current time in seconds
        return decoded.exp - now < threshold;
      } catch (error) {
        console.error("Error decoding token:", error);
        return false;
      }
    };

    // Refresh the access token using the refresh token
    const refreshAuthToken = async () => {
      try {
        const decodedRefresh = jwtDecode(refreshToken);
        const now = Date.now() / 1000;
        if (decodedRefresh.exp < now) {
          console.error("Refresh token has expired");
          logout();
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`,
          },
        });
        const data = await response.json();

        if (response.ok && data.access_token) {
          setToken(data.access_token);
          const storedUser = JSON.parse(localStorage.getItem('user'));
          storedUser.token = data.access_token;
          localStorage.setItem('user', JSON.stringify(storedUser));
        } else {
          console.error('Token refresh failed:', data.error || 'Unknown error');
          logout();
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        logout();
      }
    };

    // Initial check on mount
    const accessToken = getAccessToken();
    if (accessToken && isTokenExpiringSoon(accessToken)) {
      refreshAuthToken();
    }

    // Periodically check every 5 minutes
    const intervalId = setInterval(() => {
      const currentAccessToken = getAccessToken();
      if (currentAccessToken && isTokenExpiringSoon(currentAccessToken)) {
        refreshAuthToken();
      }
    }, 5 * 60 * 1000); // 5 minutes

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [refreshToken, setToken, logout]);
};

export default useTokenRefresh;