// src/App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DashboardHome from './pages/DashboardHome';
import Attendance from './pages/Attendance';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';
import AttendanceLogs from './pages/AttendanceLogs';
import ReportsDashboard from './pages/ReportsDashboard';
import EmployeeEnrollment from './pages/EmployeeEnrollment';
import EmployeeList from './pages/EmployeeList.js';
import EmployeeEdit from './pages/EmployeeEdit.js';
import Departments from './pages/Departments';
import SettingsPage from './pages/SettingsPage';
import AttendanceHistory from './pages/AttendanceHistory';
import LeaveRequest from './pages/LeaveRequest';
import LeaveApproval from './pages/LeaveApproval';
import TimeSheet from './pages/TimeSheet';
import LeaveDashboard from './pages/LeaveDashboard';
import DashboardLayout from './layouts/DashboardLayout';
import RoleProtectedRoute from './components/RoleProtectedRoute';
import useTokenRefresh from './hooks/UseTokenRefresh'; // Updated import to match casing
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const App = () => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const logout = () => {
    console.log("User is logging out...");
    localStorage.removeItem('user');
    setUser(null);
  };

  // Token refresh logic using the hook
  useTokenRefresh(user?.refreshToken, (newToken) => {
    setUser((prevUser) => {
      if (!prevUser) return prevUser;
      const updatedUser = { ...prevUser, token: newToken };
      return updatedUser;
    });
  }, logout);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route
          path="/"
          element={
            user ? (
              <DashboardLayout
                fullName={user.fullName}
                email={user.email}
                role={user.role}
                onLogout={logout}
              />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
          <Route index element={<DashboardHome token={user?.token} logout={logout} />} />
          <Route path="attendance" element={<Attendance token={user?.token} logout={logout} />} />
          <Route path="change_password" element={<ChangePassword token={user?.token} logout={logout} />} />
          <Route path="attendance-logs" element={<AttendanceLogs token={user?.token} logout={logout} />} />
          <Route path="timesheet" element={<TimeSheet token={user?.token} user={user} logout={logout} />} />

          <Route
            path="departments"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['manager', 'admin']}>
                <Departments token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="employee-enrollment"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['admin']}>
                <EmployeeEnrollment token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="employee-list"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['admin']}>
                <EmployeeList token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="employees/edit/:employee_id"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['admin']}>
                <EmployeeEdit token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="attendance-history"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['manager', 'admin']}>
                <AttendanceHistory token={user?.token} user={user} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="leave-request"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['employee', 'manager']}>
                <LeaveRequest token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="leave-approval"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['manager', 'admin']}>
                <LeaveApproval token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="leave-dashboard"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['employee', 'manager']}>
                <LeaveDashboard token={user?.token} user={user} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="reports-dashboard"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['manager', 'admin']}>
                <ReportsDashboard token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <RoleProtectedRoute user={user} allowedRoles={['admin']}>
                <SettingsPage token={user?.token} logout={logout} />
              </RoleProtectedRoute>
            }
          />
        </Route>
        {/* Catch-all route for 404 Page Not Found */}
        <Route path="*" element={<div>404 - Page Not Found</div>} />
      </Routes>
    </Router>
  );
};

export default App;