// src/pages/LeaveDashboard.js
import React, { useState, useEffect } from 'react';

// Helper to format a date into "Month Day, Year" (e.g., "March 12, 2025")
const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const d = new Date(dateStr);
  return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

// Helper to format a date-time into "Month Day, Year, HH:MM AM/PM"
const formatDateTime = (dateStr) => {
  if (!dateStr) return '';
  const d = new Date(dateStr);
  return d.toLocaleString('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    second: 'numeric', 
    hour12: true 
  });
};

const LeaveDashboard = ({ token }) => {
  const [logs, setLogs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [message, setMessage] = useState('');

  // Fetch leave requests for the logged-in user (backend auto-filters for employees)
  const fetchLeaveRequests = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}leave_requests?`;
      if (startDate) url += `start_date=${startDate}&`;
      if (endDate) url += `end_date=${endDate}&`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setLogs(data);
      } else {
        setMessage(data.error || 'Unexpected response from server.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Error fetching leave requests.');
    }
  };

  useEffect(() => {
    if (token) {
      fetchLeaveRequests();
    }
  }, [token, startDate, endDate]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchLeaveRequests();
  };

  return (
    <div className="container mt-4">
      <h3>My Leave Requests</h3>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleFilterSubmit} className="row mb-4">
        <div className="col-md-4">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-md-4 d-flex align-items-end">
          <button type="submit" className="btn btn-primary w-100">
            Apply Filters
          </button>
        </div>
      </form>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Requested At</th>
          </tr>
        </thead>
        <tbody>
          {logs.length > 0 ? (
            logs.map((req) => (
              <tr key={req.request_id}>
                <td>{formatDate(req.start_date)}</td>
                <td>{formatDate(req.end_date)}</td>
                <td>{req.leave_type}</td>
                <td>{req.reason || '-'}</td>
                <td>{req.status}</td>
                <td>{formatDateTime(req.created_at)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No leave requests found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button className="btn btn-secondary" onClick={fetchLeaveRequests}>
        Refresh
      </button>
    </div>
  );
};

export default LeaveDashboard;
