// src/pages/TimeSheet.js
import React, { useState, useEffect } from 'react';

const TimeSheet = ({ token, user }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(user?.employee_id || '');
  const [message, setMessage] = useState('');

  const isManagerOrAdmin = (user.role === 'manager' || user.role === 'admin');

  // If manager/admin, fetch employee list from backend
  useEffect(() => {
    if (isManagerOrAdmin && token) {
      fetch(`${process.env.REACT_APP_API_URL}all_employees_list`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(res => res.json())
        .then(data => {
          if (Array.isArray(data)) {
            setEmployees(data);
          } else {
            setMessage(data.error || 'Error fetching employees list');
          }
        })
        .catch(err => {
          console.error(err);
          setMessage('Error fetching employees list.');
        });
    }
  }, [isManagerOrAdmin, token]);

  const handleDownload = async () => {
    setMessage('');

    // Build query
    let url = `${process.env.REACT_APP_API_URL}timesheet?start_date=${startDate}&end_date=${endDate}`;
    if (isManagerOrAdmin && selectedEmployee) {
      url += `&employee_id=${selectedEmployee}`;
    }

    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const errData = await response.json();
        setMessage(errData.error || 'Error generating timesheet.');
        return;
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Timesheet_${startDate}_${endDate}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
      setMessage('Error generating timesheet.');
    }
  };

  return (
    <div className="container mt-4">
      <h3>Generate Timesheet</h3>
      {message && <div className="alert alert-info">{message}</div>}

      <div className="row mb-3">
        <div className="col-md-3">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        {isManagerOrAdmin && (
          <div className="col-md-3">
            <label className="form-label">Select Employee</label>
            <select
              className="form-control"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <option value="">-- Select an Employee --</option>
              {employees.map(emp => (
                <option key={emp.employee_id} value={emp.employee_id}>
                  {emp.full_name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-md-3 d-flex align-items-end my-4">
          <button className="btn btn-primary w-100" onClick={handleDownload}>
            Download Timesheet PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeSheet;
