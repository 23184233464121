// src/components/RoleProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const RoleProtectedRoute = ({ user, allowedRoles, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default RoleProtectedRoute;
