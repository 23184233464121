// src/pages/LeaveApproval.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { jwtDecode } from 'jwt-decode'; // Install with: npm install jwt-decode

const LeaveApproval = ({ token }) => {
  const [requests, setRequests] = useState([]);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [action, setAction] = useState(''); // 'approve' or 'reject'
  const [comment, setComment] = useState('');

  // Decode token to get user role
  const user = token ? jwtDecode(token) : { role: 'employee' };
  const userRole = user.role || 'employee';

  // Helper to format date (e.g., "March 12, 2025")
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const d = new Date(dateStr);
    return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Helper to format date and time for "Requested At" (e.g., "March 12, 2025, 1:34:54 PM")
  const formatDateTime = (dateStr) => {
    if (!dateStr) return '';
    const d = new Date(dateStr);
    return d.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
  };

  const fetchRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}leave_requests`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        console.log("Fetched leave requests:", data); // Debug log
        setRequests(data);
      } else {
        setMessage(data.error || "Unexpected response from server.");
        console.error("Non-array response:", data);
      }
    } catch (error) {
      console.error("Error fetching leave requests:", error);
      setMessage("Error fetching leave requests.");
    }
  };

  useEffect(() => {
    if (token) {
      fetchRequests();
    }
  }, [token]);

  const handleActionClick = (requestId, actionType) => {
    const request = requests.find(req => req.request_id === requestId);
    setSelectedRequest(request);
    setAction(actionType);
    setComment('');
    setShowModal(true);
  };

  const handleSubmitAction = async () => {
    if (!selectedRequest || !action) return;

    const endpoint = action === 'approve'
      ? `leave_request/${selectedRequest.request_id}/approve`
      : `leave_request/${selectedRequest.request_id}/reject`;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ comment })
      });
      const data = await response.json();
      setMessage(data.message || `${action === 'approve' ? 'Approved' : 'Rejected'} successfully.`);
      setShowModal(false);
      fetchRequests();
    } catch (error) {
      console.error(`Error ${action === 'approve' ? 'approving' : 'rejecting'} leave request:`, error);
      setMessage(`Error ${action === 'approve' ? 'approving' : 'rejecting'} leave request.`);
    }
  };

  // Determine if actions should be shown based on role and status
  const canPerformAction = (req) => {
    const status = req.status.toLowerCase();
    if (userRole === 'manager' && status === 'pending manager') return true;
    if (userRole === 'admin' && (status === 'pending manager' || status === 'pending admin')) return true;
    return false;
  };

  return (
    <div className="container mt-4">
      <h3>Leave Approvals</h3>
      {message && <div className="alert alert-info">{message}</div>}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Employee</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Requested At</th>
            <th>Manager Comment</th>
            <th>Admin Comment</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requests.length > 0 ? (
            requests.map((req) => (
              <tr key={req.request_id}>
                <td>{req.employee_name || req.employee_id}</td>
                <td>{formatDate(req.start_date)}</td>
                <td>{formatDate(req.end_date)}</td>
                <td>{req.leave_type}</td>
                <td>{req.reason}</td>
                <td>{req.status}</td>
                <td>{formatDateTime(req.created_at)}</td>
                <td>{req.manager_approval_comment || 'N/A'}</td>
                <td>{req.admin_approval_comment || 'N/A'}</td>
                <td>
                  {canPerformAction(req) && (
                    <>
                      <button
                        className="btn btn-sm btn-success me-2"
                        onClick={() => handleActionClick(req.request_id, 'approve')}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleActionClick(req.request_id, 'reject')}
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">No leave requests found.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for Approval/Rejection Comment */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{action === 'approve' ? 'Approve' : 'Reject'} Leave Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="comment">
              <Form.Label>Comment (Optional)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={`Reason for ${action === 'approve' ? 'approval' : 'rejection'}`}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant={action === 'approve' ? 'success' : 'danger'} onClick={handleSubmitAction}>
            {action === 'approve' ? 'Approve' : 'Reject'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeaveApproval;