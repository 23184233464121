// src/components/FaceVerificationModel.js
import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Modal, Button, Alert } from 'react-bootstrap';

const FaceVerificationModal = ({ token, show, onClose, onVerified }) => {
  const webcamRef = useRef(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Capture image from webcam
  const captureImage = () => {
    if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot();
      return screenshot;
    }
    return null;
  };

  // Function to perform face verification
  const verifyFace = async () => {
    if (loading) return;
    const image = captureImage();
    if (!image) {
      setMessage("No face detected. Retrying...");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}verify_face`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ image })
      });
      console.log("Response status:", response.status);
      const data = await response.json();
      console.log("Response data:", data);
      if (!response.ok) {
        setMessage(data.error || "Face verification failed. Retrying...");
      } else {
        if (data.verified) {
          setMessage("Face verified successfully.");
          onVerified();
          onClose();
        } else {
          setMessage(data.message || "Face not verified. Retrying...");
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setMessage("Error contacting verification service. Retrying...");
    }
    setLoading(false);
  };

  // Auto-capture and verify every 5 seconds until modal is closed
  useEffect(() => {
    let intervalId;
    if (show) {
      setMessage("Starting face verification...");
      intervalId = setInterval(() => {
        verifyFace();
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [show]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Face Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && <Alert variant="info">{message}</Alert>}
        <div className="container mb-3">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ width: 1280, height: 720, facingMode: "user" }}
                className="img-fluid rounded shadow-sm w-100"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FaceVerificationModal;
