// src/layouts/DashboardLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';

const DashboardLayout = ({ fullName, email, role, onLogout }) => {
  return (
    <div className="d-flex" style={{ minHeight: '100vh' }}>
      <Sidebar role={role} />
      <div className="flex-grow-1">
        <Header fullName={fullName} email={email} onLogout={onLogout} />
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
