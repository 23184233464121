// src/pages/AttendanceLogs.js
import React, { useState, useEffect } from 'react';

// Helper: Format date into "Month Day, Year" (e.g., "March 12, 2025")
const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const d = new Date(dateStr);
  return d.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

// Helper: Format time into "HH:MM:SS AM/PM"
const formatTime = (timeStr) => {
  if (!timeStr) return '';
  const d = new Date(timeStr);
  return d.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

// Helper: Reverse geocode a POINT string (e.g., "POINT(-10.807 6.316)")
// to a human-readable address using Nominatim.
const reverseGeocode = async (pointStr) => {
  if (!pointStr || !pointStr.startsWith('POINT(')) return 'Unknown Location';
  const coordsStr = pointStr.slice(6, -1); // Remove "POINT(" and ")"
  const [lon, lat] = coordsStr.split(' ');
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`
    );
    if (!response.ok) return 'Address not found';
    const data = await response.json();
    return data.display_name || 'Address not found';
  } catch (error) {
    console.error('Reverse geocoding error:', error);
    return 'Error fetching address';
  }
};

// Helper: Return only the first component of an address (before the first comma)
const getShortAddress = (fullAddress) => {
  if (!fullAddress) return '';
  return fullAddress.split(',')[0].trim();
};

// Helper: Return a CSS class for discrepancy text based on its value.
const getDiscrepancyClass = (discrepancy) => {
  if (!discrepancy || discrepancy === 'N/A') return 'text-muted';
  if (discrepancy.toLowerCase().includes('late')) return 'text-danger';
  if (discrepancy.toLowerCase().includes('early')) return 'text-success';
  return 'text-muted';
};

const AttendanceLogs = ({ token }) => {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState('');
  // Cache for reverse-geocoded addresses, keyed by record_id.
  const [addressMap, setAddressMap] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Fetch logs with optional date filters.
  const fetchLogs = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}attendance_logs?`;
      if (startDate) url += `start_date=${startDate}&`;
      if (endDate) url += `end_date=${endDate}&`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setLogs(data);
      } else {
        setError(data.error || 'Unexpected response from server.');
      }
    } catch (err) {
      console.error(err);
      setError('Error fetching attendance logs.');
    }
  };

  useEffect(() => {
    if (token) {
      fetchLogs();
    }
  }, [token, startDate, endDate]);

  // Reverse-geocode any log that has a POINT location and cache it.
  useEffect(() => {
    const fetchAddresses = async () => {
      const newMap = { ...addressMap };
      for (const log of logs) {
        if (log.location && log.location.startsWith('POINT(') && !newMap[log.record_id]) {
          const fullAddr = await reverseGeocode(log.location);
          newMap[log.record_id] = fullAddr;
        }
      }
      setAddressMap(newMap);
    };

    if (logs.length > 0) {
      fetchAddresses();
    }
  }, [logs]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchLogs();
  };

  return (
    <div className="container mt-4">
      <h3>Attendance Logs</h3>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleFilterSubmit} className="mb-4">
        <div className="row">
          <div className="col-md-3">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="col-md-3 d-flex align-items-end">
            <button type="submit" className="btn btn-primary w-100">
              Apply Filters
            </button>
          </div>
        </div>
      </form>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Location</th>
            <th>Spoof Score</th>
            <th>Discrepancy</th>
          </tr>
        </thead>
        <tbody>
          {logs.length > 0 ? (
            logs.map((log) => (
              <tr key={log.record_id}>
                <td>{formatDate(log.attendance_date)}</td>
                <td>{formatTime(log.check_in_time)}</td>
                <td>{log.check_out_time ? formatTime(log.check_out_time) : '-'}</td>
                <td>
                  {log.location && log.location.startsWith('POINT(')
                    ? getShortAddress(addressMap[log.record_id] || 'Fetching address...')
                    : log.location || '-'}
                </td>
                <td>{log.spoof_score !== null ? log.spoof_score : '-'}</td>
                <td>
                  <span className={getDiscrepancyClass(log.discrepancy)}>
                    {log.discrepancy || '-'}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No attendance records found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceLogs;
