// src/pages/AttendanceHistory.js
import React, { useState, useEffect } from 'react';

const AttendanceHistory = ({ token, user }) => {
  const [logs, setLogs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [message, setMessage] = useState('');
  // Cache for reverse-geocoded addresses: { record_id: "full address" }
  const [addressMap, setAddressMap] = useState({});

  // Reverse geocode: Convert POINT string (e.g. "POINT(-10.807 6.316)") into a human-readable address.
  const reverseGeocode = async (pointStr) => {
    if (!pointStr || !pointStr.startsWith('POINT(')) return 'Unknown Location';
    const coordsStr = pointStr.slice(6, -1); // Remove "POINT(" and ")"
    const [lon, lat] = coordsStr.split(' ');
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`
      );
      if (!response.ok) {
        return 'Address not found';
      }
      const data = await response.json();
      return data.display_name || 'Address not found';
    } catch (error) {
      console.error('Reverse geocoding error:', error);
      return 'Error fetching address';
    }
  };

  // Format a date string into "Month Day, Year" (e.g., "March 12, 2025")
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const d = new Date(dateStr);
    return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Format a datetime string into time only (e.g., "1:34:54 PM")
  const formatTime = (dateStr) => {
    if (!dateStr) return '';
    const d = new Date(dateStr);
    return d.toLocaleTimeString('en-US');
  };

  // Helper: Return the first part of an address (before the first comma)
  const getShortAddress = (fullAddress) => {
    if (!fullAddress) return '';
    return fullAddress.split(',')[0].trim();
  };

  const fetchLogs = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}attendance_logs?`;
      if (startDate) url += `start_date=${startDate}&`;
      if (endDate) url += `end_date=${endDate}&`;
      if ((user.role === 'manager' || user.role === 'admin') && selectedDepartment) {
        url += `department_id=${selectedDepartment}&`;
      }
  
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      // Try to parse the response as JSON.
      let data;
      try {
        data = await response.json();
      } catch (e) {
        console.error("JSON Parsing Error:", e);
        setMessage("Unexpected response format from server.");
        return;
      }
  
      if (!response.ok) {
        // Handle server-side errors.
        setMessage(data.error || 'Unexpected response from server.');
        return;
      }
  
      // If data is an array, use it; otherwise, check for a "logs" property.
      let logsData = [];
      if (Array.isArray(data)) {
        logsData = data;
      } else if (data.logs && Array.isArray(data.logs)) {
        logsData = data.logs;
      } else {
        setMessage(data.error || 'Unexpected response format from server.');
        return;
      }
  
      setLogs(logsData);
      if (logsData.length === 0) {
        setMessage("No attendance logs found.");
      } else {
        setMessage("");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setMessage('Error fetching attendance logs.');
    }
  };

  // Fetch department list (for managers/admins)
  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}departments`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setDepartments(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchLogs();
      if (user.role === 'manager' || user.role === 'admin') {
        fetchDepartments();
      }
    }
  }, [token, startDate, endDate, selectedDepartment, user.role]);

  // Reverse geocode each log's location if it's in POINT format and update the addressMap
  useEffect(() => {
    const fetchAddresses = async () => {
      const newMap = { ...addressMap };
      for (const log of logs) {
        if (log.location && log.location.startsWith('POINT(') && !newMap[log.record_id]) {
          const fullAddr = await reverseGeocode(log.location);
          newMap[log.record_id] = fullAddr;
        }
      }
      setAddressMap(newMap);
    };

    if (logs.length > 0) {
      fetchAddresses();
    }
  }, [logs]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchLogs();
  };

  return (
    <div className="container mt-4">
      <h3>Attendance History</h3>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleFilterSubmit} className="mb-4">
        <div className="row">
          <div className="col-md-3">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          {(user.role === 'manager' || user.role === 'admin') && (
            <div className="col-md-3">
              <label>Department</label>
              <select
                className="form-control"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <option value="">All Departments</option>
                {departments.map((dept) => (
                  <option key={dept.department_id} value={dept.department_id}>
                    {dept.department_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-md-3 d-flex align-items-end">
            <button type="submit" className="btn btn-primary w-100">
              Apply Filters
            </button>
          </div>
        </div>
      </form>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Employee</th>
            <th>Departments</th>
            <th>Status</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {logs.length > 0 ? (
            logs.map((log) => (
              <tr key={log.record_id}>
                <td>{formatDate(log.attendance_date)}</td>
                <td>{formatTime(log.check_in_time)}</td>
                <td>{log.check_out_time ? formatTime(log.check_out_time) : '-'}</td>
                <td>{log.employee_name}</td>
                <td>{log.department_names || '-'}</td>
                <td>{log.discrepancy || '-'}</td>
                <td>
                  {log.location && log.location.startsWith('POINT(')
                    ? getShortAddress(addressMap[log.record_id] || 'Fetching address...')
                    : log.location || '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No attendance records found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceHistory;
