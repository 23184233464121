// src/pages/EmployeeEdit.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EmployeeEdit = ({ token }) => {
  const { employee_id } = useParams();
  const navigate = useNavigate();

  const [employee, setEmployee] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('employee');
  const [managerId, setManagerId] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hireDate, setHireDate] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [allowedLocations, setAllowedLocations] = useState('');
  const [additionalImage, setAdditionalImage] = useState(null);
  const [managers, setManagers] = useState([]);
  const [message, setMessage] = useState('');
  const [managerMessage, setManagerMessage] = useState('');

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const resp = await fetch(`${process.env.REACT_APP_API_URL}employees/${employee_id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await resp.json();
        if (!data.error) {
          console.log('Fetched Employee Data:', data); // Debug log to inspect the response
          setEmployee(data);
          setFirstName(data.first_name || '');
          setLastName(data.last_name || '');
          setEmail(data.email || '');
          setRole(data.role || 'employee');
          setManagerId(data.report_to || '');
          
          // Convert GMT date strings to YYYY-MM-DD
          setDateOfBirth(data.date_of_birth ? new Date(data.date_of_birth).toISOString().split('T')[0] : '');
          setPhoneNumber(data.phone_number || '');
          setHireDate(data.hire_date ? new Date(data.hire_date).toISOString().split('T')[0] : '');
          setJobTitle(data.job_title || '');
          setAllowedLocations(data.allowed_locations || '');
        } else {
          setMessage("Employee not found.");
        }
      } catch (error) {
        console.error(error);
        setMessage("Error fetching employee data.");
      }
    };

    const fetchManagers = async () => {
      try {
        const resp = await fetch(`${process.env.REACT_APP_API_URL}employees_list`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await resp.json();
        if (Array.isArray(data)) {
          const mgrList = data.filter(emp => emp.role === 'manager' || emp.role === 'admin');
          setManagers(mgrList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (token && employee_id) {
      fetchEmployee();
      fetchManagers();
    }
  }, [token, employee_id]);

  const handleFileChange = (e) => {
    setAdditionalImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('role', role);
    if (dateOfBirth) formData.append('date_of_birth', dateOfBirth);
    if (phoneNumber) formData.append('phone_number', phoneNumber);
    if (hireDate) formData.append('hire_date', hireDate);
    if (jobTitle) formData.append('job_title', jobTitle);
    if (allowedLocations) formData.append('allowed_locations', allowedLocations);
    if (additionalImage) formData.append('additional_image', additionalImage);

    try {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}employees/${employee_id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await resp.json();
      if (!resp.ok) {
        setMessage(`Error: ${data.error || 'Unknown error'}`);
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while updating employee.');
    }
  };

  const handleManagerChange = async (e) => {
    e.preventDefault();
    setManagerMessage('');

    try {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}employees/${employee_id}/manager`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ manager_id: managerId || null }),
      });
      const data = await resp.json();
      if (!resp.ok) {
        setManagerMessage(`Error: ${data.error || 'Unknown error'}`);
      } else {
        setManagerMessage('Manager updated successfully.');
        // Refresh employee data to reflect the change
        const employeeResp = await fetch(`${process.env.REACT_APP_API_URL}employees/${employee_id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const updatedData = await employeeResp.json();
        setEmployee(updatedData);
        setManagerId(updatedData.report_to || '');
      }
    } catch (error) {
      console.error(error);
      setManagerMessage('An error occurred while updating the manager.');
    }
  };

  if (!employee) return <div>Loading employee data...</div>;

  return (
    <div className="container mt-4">
      <h3 className="mb-4">Edit Employee #{employee_id}</h3>
      {message && <div className="alert alert-info">{message}</div>}

      {/* General Employee Details Form */}
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <h4>Employee Details</h4>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Role</label>
            <select
              className="form-control"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="employee">Employee</option>
              <option value="manager">Manager</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Date of Birth</label>
            <input
              type="date"
              className="form-control"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Hire Date</label>
            <input
              type="date"
              className="form-control"
              value={hireDate}
              onChange={(e) => setHireDate(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Job Title</label>
            <input
              type="text"
              className="form-control"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-3">
          <label>Allowed Locations</label>
          <textarea
            className="form-control"
            value={allowedLocations}
            onChange={(e) => setAllowedLocations(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Additional Face Image</label>
          <input
            type="file"
            className="form-control"
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
        <button type="submit" className="btn custom-gradiant w-100 mb-4">
          Save Employee Changes
        </button>
      </form>

      {/* Manager Assignment Section */}
      <form onSubmit={handleManagerChange}>
        <h4>Change Manager</h4>
        {managerMessage && <div className="alert alert-info">{managerMessage}</div>}
        <div className="mb-3">
          <label>Assigned Manager</label>
          <select
            className="form-control"
            value={managerId}
            onChange={(e) => setManagerId(e.target.value)}
          >
            <option value="">No Manager</option>
            {managers.map((mgr) => (
              <option key={mgr.employee_id} value={mgr.employee_id}>
                {mgr.first_name} {mgr.last_name} (ID: {mgr.employee_id})
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Update Manager
        </button>
      </form>
    </div>
  );
};

export default EmployeeEdit;