// src/pages/Departments.js
import React, { useState, useEffect } from 'react';

const Departments = ({ token }) => {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState('');
  const [message, setMessage] = useState('');

  // Fetch all departments from the backend
  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}departments`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setDepartments(data);
      } else {
        setMessage(data.error || 'Unexpected response from server.');
      }
    } catch (error) {
      console.error(error);
      setMessage('Error fetching departments.');
    }
  };

  useEffect(() => {
    if (token) {
      fetchDepartments();
    }
  }, [token]);

  // Create a new department
  const handleCreate = async () => {
    if (!newDepartment.trim()) {
      setMessage('Please enter a department name.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}departments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ department_name: newDepartment })
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(data.error || 'Error creating department.');
      } else {
        setMessage(data.message);
        setNewDepartment('');
        fetchDepartments();
      }
    } catch (error) {
      console.error(error);
      setMessage('Error creating department.');
    }
  };

  // Update an existing department's name
  const handleUpdate = async (id) => {
    const newName = prompt('Enter the new department name:');
    if (!newName) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}departments/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ department_name: newName })
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(data.error || 'Error updating department.');
      } else {
        setMessage(data.message);
        fetchDepartments();
      }
    } catch (error) {
      console.error(error);
      setMessage('Error updating department.');
    }
  };

  // Delete a department
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this department?')) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}departments/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(data.error || 'Error deleting department.');
      } else {
        setMessage(data.message);
        fetchDepartments();
      }
    } catch (error) {
      console.error(error);
      setMessage('Error deleting department.');
    }
  };

  // Helper to format date (e.g., "March 12, 2025")
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const d = new Date(dateStr);
    return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div className="container mt-4">
      <h3>Departments Management</h3>
      {message && <div className="alert alert-info">{message}</div>}
      
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="New department name"
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
        />
        <button className="btn btn-primary mt-2" onClick={handleCreate}>
          Create Department
        </button>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Department Name</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((dept) => (
            <tr key={dept.department_id}>
              <td>{dept.department_name}</td>
              <td>{formatDate(dept.created_at)}</td>
              <td>
                <button className="btn btn-sm btn-warning me-2" onClick={() => handleUpdate(dept.department_id)}>
                  Edit
                </button>
                <button className="btn btn-sm btn-danger" onClick={() => handleDelete(dept.department_id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Departments;
