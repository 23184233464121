import React, { useState, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';
import FaceVerificationModal from '../components/FaceVerificationModal';

const Attendance = ({ token }) => {
  const [clockedIn, setClockedIn] = useState(false);
  const [message, setMessage] = useState('');
  const [address, setAddress] = useState('');
  const [showFaceModal, setShowFaceModal] = useState(false);
  const [verificationType, setVerificationType] = useState('clock_in'); // "clock_in" or "clock_out"

  // Retrieve GPS coordinates with improved options
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: { enableHighAccuracy: true, maximumAge: 0 },
    userDecisionTimeout: 10000,
    watchPosition: true,
  });

  // Reverse geocode to get a human-readable address
  useEffect(() => {
    if (coords && coords.latitude && coords.longitude) {
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coords.latitude}&lon=${coords.longitude}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data && data.display_name) {
            setAddress(data.display_name);
          } else {
            setAddress('Address not found');
          }
        })
        .catch((error) => {
          console.error('Error fetching address:', error);
          setAddress('Error fetching address');
        });
    }
  }, [coords]);

  // Function to send data to backend
  const sendAttendanceData = async (endpoint, payload) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(`Error: ${data.error}`);
      } else {
        setMessage(data.message);
      }
      return data;
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while sending attendance data.');
      return null;
    }
  };

  // Function to fetch current attendance status from backend
  const fetchStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}current_status`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        setClockedIn(data.clocked_in);
      } else {
        setMessage(`Error fetching status: ${data.error}`);
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while fetching attendance status.');
    }
  };

  useEffect(() => {
    if (token) {
      fetchStatus();
    }
  }, [token]);

  // Open verification modal with type (clock_in or clock_out)
  const handleOpenVerificationModal = (type) => {
    if (!address || address === 'Error fetching address' || address === 'Address not found') {
      setMessage('Cannot verify face until a valid location is detected.');
    } else {
      setVerificationType(type);
      setShowFaceModal(true);
    }
  };

  // Handle Clock Out after face verification (will be triggered from modal)
  const handleClockOutAfterVerification = async () => {
    if (!coords) {
      setMessage('GPS coordinates not available.');
      return;
    }
    const payload = {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
    const data = await sendAttendanceData('clock_out', payload);
    if (data && data.message) {
      await fetchStatus();
    }
  };

  // The modal's onVerified callback handles both clock in and clock out based on verificationType.
  const handleVerified = async () => {
    if (!coords) {
      setMessage('GPS coordinates not available.');
      return;
    }
    const payload = {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
    let endpoint = verificationType; // "clock_in" or "clock_out"
    const data = await sendAttendanceData(endpoint, payload);
    if (data && data.message) {
      await fetchStatus();
    }
  };

  return (
    <div className="container mt-4">
      <FaceVerificationModal
        token={token}
        show={showFaceModal}
        onClose={() => setShowFaceModal(false)}
        onVerified={handleVerified}
      />
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white">
          <h3>Attendance</h3>
        </div>
        <div className="card-body">
          {message && <div className="alert alert-info">{message}</div>}
          <p>
            Status:{' '}
            {clockedIn ? (
              <span className="text-success fw-bold">Clocked In</span>
            ) : (
              <span className="text-danger fw-bold">Clocked Out</span>
            )}
          </p>
          <div className="row">
            <div className="col-md-6">
              {/* Instead of opening the webcam preview immediately, we just display a message */}
              <p className="text-muted">Face verification will be used when you clock in or out.</p>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <h5>Location Info:</h5>
                {isGeolocationAvailable ? (
                  isGeolocationEnabled ? (
                    coords ? (
                      <div>
                        <p>Latitude: {coords.latitude}</p>
                        <p>Longitude: {coords.longitude}</p>
                        <p>Accuracy: {coords.accuracy} meters</p>
                        <p>
                          <strong>Address:</strong>{' '}
                          {address ? address : 'Fetching address...'}
                        </p>
                      </div>
                    ) : (
                      <p>Fetching location...</p>
                    )
                  ) : (
                    <p>Geolocation is not enabled.</p>
                  )
                ) : (
                  <p>Your browser does not support Geolocation.</p>
                )}
              </div>
              <div className="d-grid gap-2">
                {!clockedIn ? (
                  <button
                    className="btn btn-success btn-lg"
                    onClick={() => handleOpenVerificationModal("clock_in")}
                  >
                    Verify Face & Clock In
                  </button>
                ) : (
                  <button
                    className="btn btn-danger btn-lg"
                    onClick={() => handleOpenVerificationModal("clock_out")}
                  >
                    Verify Face & Clock Out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
