// src/pages/LeaveRequest.js
import React, { useState, useEffect } from 'react';

const LeaveRequest = ({ token, refreshDashboard }) => {
  // State for form fields
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [reason, setReason] = useState('');

  // Additional states
  const [managerEmail, setManagerEmail] = useState('');
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [message, setMessage] = useState('');

  // Fetch available leave types from the backend
  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}leave_types`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
        if (!response.ok) {
          const errData = await response.json();
          throw new Error(errData.error || "Failed to fetch leave types");
        }
        const data = await response.json();
        setLeaveTypes(data);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    if (token) {
      fetchLeaveTypes();
    }
  }, [token]);

  // Fetch manager email from the backend
  useEffect(() => {
    const fetchManagerEmail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}manager_email`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        });
        if (!response.ok) {
          const errData = await response.json();
          throw new Error(errData.error || "Failed to fetch manager email");
        }
        const data = await response.json();
        setManagerEmail(data.manager_email);
      } catch (error) {
        console.error("Error fetching manager email:", error);
      }
    };

    if (token) {
      fetchManagerEmail();
    }
  }, [token]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    const payload = {
      start_date: startDate,
      end_date: endDate,
      leave_type: leaveType,
      reason
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}leave_request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      if (!response.ok) {
        setMessage(data.error || "Error submitting leave request.");
      } else {
        setMessage(data.message);
        // Clear form fields on success
        setStartDate('');
        setEndDate('');
        setLeaveType('');
        setReason('');
        // Optionally, refresh the dashboard if provided
        if (typeof refreshDashboard === 'function') {
          refreshDashboard();
        }
      }
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while submitting the leave request.");
    }
  };

  return (
    <div className="container mt-4">
      <h3>Request Leave</h3>
      {/* Show manager's email if available */}
      {managerEmail && (
        <div className="mb-3">
          <label className="form-label">Your Manager's Email:</label>
          <input
            type="email"
            className="form-control"
            value={managerEmail}
            disabled
          />
        </div>
      )}
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          {/* Start Date */}
          <div className="col-md-6">
            <label className="form-label">Start Date</label>
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          {/* End Date */}
          <div className="col-md-6">
            <label className="form-label">End Date</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </div>
        </div>
        {/* Leave Type */}
        <div className="row mb-3">
          <div className="col-12">
            <label className="form-label">Leave Type</label>
            <select
              className="form-control"
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
              required
            >
              <option value="">Select Leave Type</option>
              {leaveTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>
        {/* Reason */}
        <div className="row mb-3">
          <div className="col-12">
            <label className="form-label">Reason (Optional)</label>
            <textarea
              className="form-control"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <button type="submit" className="btn btn-primary">
              Submit Leave Request
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LeaveRequest;
