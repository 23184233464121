// src/layouts/Header.js
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';


const Header = ({ fullName, email, onLogout }) => {
  // Handle logout and close dropdown
  const handleLogout = (event) => {
    event.preventDefault();
    // Close the dropdown (simulate a click to close it)
    const dropdownToggle = document.getElementById('navbarDropdownMenuLink');
    if (dropdownToggle) {
      dropdownToggle.click();
    }
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <nav className="navbar navbar-expand navbar-light custom-gradiant border-bottom">
      <div className="container-fluid">
        {/* Left side: page title */}

        <div className="d-none d-md-block">
          <h5 className="mb-0 ms-5">HLB Liberia</h5>
        </div>

        {/* Mobile menu button */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Right side: user dropdown */}
        <div className="ms-auto d-flex align-items-center">
          <FaUserCircle size={28} className="text-white me-2" />
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="#!"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {fullName}
              </a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <span className="dropdown-item-text text-black">{email}</span>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                  <a className="dropdown-item text-black" href="/change_password">
                    Change Password
                  </a>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                  <button className="dropdown-item text-black" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
