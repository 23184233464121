import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReportsDashboard = ({ token }) => {
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [summaryCards, setSummaryCards] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [message, setMessage] = useState('');

  const fetchReportsData = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}reports_dashboard?`;
      const params = [];
      if (selectedDepartment) params.push(`department_id=${selectedDepartment}`);
      if (selectedEmployee) params.push(`employee_id=${selectedEmployee}`);
      if (startDate) params.push(`start_date=${startDate}`);
      if (endDate) params.push(`end_date=${endDate}`);
      if (params.length > 0) url += params.join('&');

      const response = await fetch(url, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      // Check if response is JSON
      const contentType = response.headers.get('Content-Type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        throw new Error(`Server returned non-JSON response (${response.status}): ${text.slice(0, 100)}...`);
      }

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `HTTP error ${response.status}`);
      }

      setSummaryCards(data.summary_cards || null);

      // Build line chart data
      if (data.line_chart) {
        setLineChartData({
          labels: data.line_chart.dates || [],
          datasets: [
            {
              label: 'Present',
              data: data.line_chart.present || [],
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              tension: 0.2,
            },
            {
              label: 'Absent',
              data: data.line_chart.absent || [],
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              tension: 0.2,
            },
          ],
        });
      } else {
        setLineChartData(null);
      }

      // Build bar chart data
      if (data.bar_chart) {
        setBarChartData({
          labels: data.bar_chart.labels || [],
          datasets: [
            {
              label: 'Present',
              data: data.bar_chart.present || [],
              backgroundColor: 'rgba(13, 102, 66, 0.93)',
            },
            {
              label: 'Absent',
              data: data.bar_chart.absent || [],
              backgroundColor: 'rgba(171, 10, 10, 0.9)',
            },
          ],
        });
      } else {
        setBarChartData(null);
      }

      setDepartments(data.departments || []);
      setEmployees(data.employees || []);
      setMessage('');
    } catch (error) {
      console.error('Error fetching reports data:', error);
      setMessage(error.message || 'Error fetching reports data');
    }
  };

  useEffect(() => {
    if (token) fetchReportsData();
  }, [token]);

  const handleApplyFilters = (e) => {
    e.preventDefault();
    fetchReportsData();
  };

  const downloadReport = async () => {
    try {
      let url = `${process.env.REACT_APP_API_URL}download_report?`;
      const params = [];
      if (selectedDepartment) params.push(`department_id=${selectedDepartment}`);
      if (selectedEmployee) params.push(`employee_id=${selectedEmployee}`);
      if (startDate) params.push(`start_date=${startDate}`);
      if (endDate) params.push(`end_date=${endDate}`);
      if (params.length > 0) url += params.join('&');

      const response = await fetch(url, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (!response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          throw new Error(errorData.error || `HTTP error ${response.status}`);
        } else {
          const text = await response.text();
          throw new Error(`Server returned non-JSON response (${response.status}): ${text.slice(0, 100)}...`);
        }
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `Attendance_Report_${startDate || 'all'}_to_${endDate || 'all'}.xlsx`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMessage('Report downloaded successfully');
    } catch (error) {
      console.error('Download error:', error);
      setMessage(error.message || 'An error occurred while downloading the report');
    }
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-3">Advanced Reports Dashboard</h3>
      {message && <div className="alert alert-info">{message}</div>}

      <form onSubmit={handleApplyFilters} className="row g-3 mb-3">
        <div className="col-12 col-md-2">
          <label className="form-label">Department</label>
          <select
            className="form-control"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">All Departments</option>
            {departments.map((dept) => (
              <option key={dept.department_id} value={dept.department_id}>
                {dept.department_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2">
          <label className="form-label">Employee</label>
          <select
            className="form-control"
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
          >
            <option value="">All Employees</option>
            {employees.map((emp) => (
              <option key={emp.employee_id} value={emp.employee_id}>
                {emp.full_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-6 col-md-2 d-grid">
          <button type="submit" className="btn btn-secondary">
            Apply Filters
          </button>
        </div>
        <div className="col-6 col-md-2 d-grid">
          <button type="button" className="btn btn-success" onClick={downloadReport}>
            Download Report
          </button>
        </div>
      </form>

      {summaryCards && (
        <div className="row text-center justify-content-center mb-4">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <div className="card custom-gradiant shadow-sm h-100">
              <div className="card-body text-white">
                <h6>Departments</h6>
                <h4>{summaryCards.totalDepartments || 0}</h4>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2 mb-3 mb-md-0">
            <div className="card shadow-sm bg-success h-100">
              <div className="card-body text-white">
                <h6>Present</h6>
                <h4>{summaryCards.totalPresent || 0}</h4>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2 mb-3 mb-md-0">
            <div className="card shadow-sm bg-danger h-100">
              <div className="card-body text-white">
                <h6>Absent</h6>
                <h4>{summaryCards.totalAbsent || 0}</h4>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2 mb-3 mb-md-0">
            <div className="card shadow-sm bg-warning h-100">
              <div className="card-body text-white">
                <h6>On Leave</h6>
                <h4>{summaryCards.totalOnLeave || 0}</h4>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="card shadow-sm bg-info h-100">
              <div className="card-body text-white">
                <h6>Arrival Early</h6>
                <h4>{summaryCards.arrivalEarly || 0}</h4>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <h5>Attendance Trends (Line)</h5>
          {lineChartData ? (
            <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Present vs. Absent Over Time' },
                },
              }}
            />
          ) : (
            <p>No line chart data available.</p>
          )}
        </div>
        <div className="col-12 col-lg-6 mb-4">
          <h5>Attendance Distribution (Bar)</h5>
          {barChartData ? (
            <Bar
              data={barChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: 'top' },
                  title: { display: true, text: 'Department/Employee Distribution' },
                },
              }}
            />
          ) : (
            <p>No bar chart data available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsDashboard;